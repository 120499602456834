<template>
    <BModal
        id="ratingDetailsModal"
        ref="ratingDetailsModal"
        centered
        data-cy="ratingDetailsModal"
        hide-header-close
        modal-class="bar bar-primary"
        no-close-on-backdrop
        size="lg"
        static
        title="Rating Record Details"
        visible
        @hidden="closeDetailModal('rating')"
    >
        <div v-if="!loading">
            <div class="row mb-3">
                <div class="col-6">
                    <h4 class="font-weight-bold">
                        Policy System
                    </h4>
                    {{ selectedResult.policySystem }}
                </div>

                <div class="col-6">
                    <h4 class="font-weight-bold">
                        Created At
                    </h4>
                    {{ date(selectedResult.createdAt) }}
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-6">
                    <h4 class="font-weight-bold">
                        Request Started At
                    </h4>
                    {{ date(selectedResult.requestStartedAt, 'MM/DD/YYYY [at] h:m:s.SSS a') }}
                </div>

                <div class="col-6">
                    <h4 class="font-weight-bold">
                        Request Ended At
                    </h4>
                    {{ date(selectedResult.requestEndedAt, 'MM/DD/YYYY [at] h:m:s.SSS a') }}
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <h4 class="font-weight-bold">
                        Request Total Time
                    </h4>
                    {{ requestTime }}
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h4 class="font-weight-bold">
                        Request
                    </h4>

                    <AceEditor
                        ref="editor"
                        mode="json"
                        :options="editorOptions"
                        theme="chrome"
                        :value="formatted(selectedResult.payload)"
                    />
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h4 class="font-weight-bold">
                        Response
                    </h4>

                    <AceEditor
                        ref="editor"
                        :mode="isJsonResponse ? 'json' : 'text'"
                        :options="editorOptions"
                        theme="chrome"
                        :value="isJsonResponse ? formatted(selectedResult.jsonResponse) : selectedResult.response"
                    />
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h4 class="font-weight-bold">
                        Extension
                    </h4>

                    <AceEditor
                        ref="editor"
                        :mode="isJsonResponse ? 'json' : 'text'"
                        :options="editorOptions"
                        theme="chrome"
                        :value="isJsonResponse ? formatted(selectedResult.extension) : selectedResult.response"
                    />
                </div>
            </div>
        </div>

        <div v-else>
            <FontAwesomeIcon
                far
                icon="spinner"
                pulse
            />
        </div>

        <template #modal-footer>
            <div class="flex flex-row align-items-center">
                <BButton
                    data-cy="closeModalBtn"
                    block
                    variant="secondary"
                    @click="closeDetailModal('rating')"
                >
                    Close Details
                </BButton>
            </div>
        </template>
    </BModal>
</template>

<script>
    import dayjs from 'dayjs';
    import {mapState} from 'vuex';

    import details from '@/mixins/details';

    export default {
        name: 'STRatingDetailsModal',
        mixins: [details],
        data() {
            return {
                loading: false
            };
        },
        computed: {
            requestTime() {
                let start = dayjs(this.selectedResult.requestStartedAt),
                    end = dayjs(this.selectedResult.requestEndedAt),
                    diff = start.diff(end, 'millisecond');

                return `${diff / 1000} seconds`;
            },
            ...mapState(['selectedResult'])
        },
        async created() {
            await this.initDetails('rating');
        },
    };
</script>
