import toolboxFilters from '@imt/vue-toolbox/src/mixins/filters';
import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';
import {mapActions, mapMutations, mapState} from 'vuex';

export default {
    mixins: [toastsMixin],
    data() {
        return {
            editorOptions: {
                readOnly: true,
                wrap: true,
            },
        };
    },
    computed: {
        isJsonResponse() {
            return !isEmpty(this.selectedResult?.jsonResponse);
        },
        ...mapState(['selectedResult']),
    },
    methods: {
        closeDetailModal(detailType) {
            this.SET_DATA({type: 'selectedResult', data: {}});
            this.$router.push(this.$route.params.previousUrl?.fullPath ? {path: this.$route.params.previousUrl?.fullPath} : {name: detailType});
        },
        parseJson(object) {
            try {
                return JSON.parse(object);
            } catch (e) {
                return object;
            }
        },
        formatted(object) {
            return JSON.stringify(object, null, 4);
        },
        async initDetails(detailType) {
            this.loading = true;

            if (!this.selectedResult.id) {
                try {
                    await this.fetchRecordById({
                        id: detailType === 'service' ? this.$route.params.serviceId : this.$route.params.ratingId,
                        isService: detailType === 'service',
                        fromSearch: false
                    });
                } catch (e) {
                    this.error(`${upperFirst(detailType)} details were not found or do not exist.`, `${upperFirst(detailType)} Details Not Found`);
                    await this.$router.push({name: detailType});
                }
            }

            this.loading = false;
        },
        ...toolboxFilters.filters,
        ...mapActions(['fetchRecordById']),
        ...mapMutations(['SET_DATA']),
    },
};
